import React, { useCallback } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { carrierSpotRequestTableActions, shipmentInformationInputModalActions } from '../../_actions';
import { connect } from 'react-redux';

import freightoptLogoSmall from '../../_assets/images/newLogo.jpg'
import Grid from '@mui/material/Grid';
import { Loading, Success } from '../Shared'
import { useDispatch, useSelector } from 'react-redux';
import ShipmentInformationPreview from '_components/ShipmentInformationPreview/ShipmentInformationPreview'
import SuiButton from "_softUI/components/SuiButton";
import Tooltip from '@mui/material/Tooltip';
import { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from '@mui/material/TableCell';
import HeaderCell from '_components/Tables/HeaderCell'
import SuiBox from "_softUI/components/SuiBox";

import BasicInfoView from '_components/ShipmentInformationPreview/BasicInfoView'
import LocationView from '_components/ShipmentInformationPreview/LocationView'
import breakpoints from "_softUI/assets/theme/base/breakpoints";
import Cube from "_softUI/icons/Cube";
import Document from "_softUI/icons/Document";
import Settings from "_softUI/icons/Settings";
import DocumentsAndLabels from '_components/ShipmentDisplayModal/DocumentsAndLabels'
import Admin from '_components/ShipmentDisplayModal/Admin'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Link from '@mui/material/Link';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import { QuoteRequest } from './QuoteRequest';
import { CurrentQuote } from './CurrentQuote';
import { GoodsPreview } from '_components/Common/Goods/GoodsPreview';
import ShipmentPreviewSectionContainer from '_components/Common/ShipmentPreviewSectionContainer';
import AdditionalInformationView from '_components/ShipmentInformationPreview/AdditionalInformationView';

export function CarrierSpotDisplayModal({ state }) {

    const dispatch = useDispatch();
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);
    var authState = useSelector(state => state.authentication);
    const isAdmin = authState.user.roles.find(x => x === 'Admin')
    const isSpot = state.selectedShipment.shipmentType === 1
    const handleClose = () => {
        dispatch(carrierSpotRequestTableActions.closeModal());
    }

    const handleSetTabValue = (event, newValue) => setTabValue(newValue);


    const tabTitle = isSpot ? "Request" : "Booking"

    return (
        <React.Fragment>

            <Dialog
                fullWidth={true}
                maxWidth='lg'
                open={state.modalOpen}
                onClose={() => handleClose}
                aria-labelledby="max-width-dialog-title"
                scroll='body'

            >

                {state.modalLoading && <Loading text="Loading" size="h5"></Loading>}

                {!state.modalLoading &&

                    <>
                        <img style={{ width: '250px', margin: '10px 0 0 25px' }} alt="FreighOpt" src={freightoptLogoSmall} />
                        <Divider sx={{ m: 0 }} />
                        <Grid container sx={{ backgroundColor: '#f8f8f8' }}>
                            <Grid item xs={5}>

                            </Grid>
                            <Grid item xs={7}>
                                <Tabs
                                    orientation={tabsOrientation}
                                    value={tabValue}
                                    onChange={handleSetTabValue}
                                    sx={{ background: "transparent" }}
                                >
                                    <Tab label="Request" icon={<Cube />} value={0} />
                                    <Tab label="Quote" icon={<Document />} value={1} />
                                    {/* <Tab label="Documents and labels" icon={<Document />} value={2} /> */}
                                    {/* {isAdmin && <Tab label="Admin" icon={<Settings />} value={3} />} */}
                                </Tabs>
                            </Grid>
                        </Grid>

                        <Divider sx={{ m: 0 }} />
                        {/* <DialogContent sx={{ height: '750px', overflow: 'auto' }}> */}
                        <DialogContent sx={{ height: '900px', overflow: 'auto', paddingRight:'2rem', paddingLeft:'2rem' }}>
                            {state.modalLoading &&
                                <Loading text="Loading" size="h5"></Loading>
                            }
                            {(!state.modalLoading) && <>
                                {tabValue === 0 &&
                                    <>
                                        <BasicInfoView state={state.selectedShipment} carrier={true} />

                                        <LocationView state={state.selectedShipment.locations[0]} />

                                        <LocationView state={state.selectedShipment.locations[1]} />
                                        <ShipmentPreviewSectionContainer title="Goods">
                                        <GoodsPreview goods={state.selectedShipment.goods} state={state}/>
                                        </ShipmentPreviewSectionContainer>
                                        <ShipmentPreviewSectionContainer title="Additional information">
            <AdditionalInformationView state={state.selectedShipment.additionalInformation} />
          </ShipmentPreviewSectionContainer>
          {state.selectedShipment.file.length > 0 && 
          <ShipmentPreviewSectionContainer title="Documents">
          <SuiBox sx={{width:'100%'}}>
                <TableContainer sx={{ boxShadow: "none" }}>
                    <Table>
                        <SuiBox component="thead">
                            <TableRow>
                                <HeaderCell>filename</HeaderCell>
                                <HeaderCell>download</HeaderCell>
                 
                            </TableRow>
                        </SuiBox>
                        <TableBody>{state.selectedShipment.file.map((x, i) =>

                            <TableRow key={i}>
                                <TableCell align="left">{x.name}</TableCell>
                                <TableCell align="center"> <Link href={x.path} ><FileDownloadIcon /></Link></TableCell>
                       
                            </TableRow>
                        )}
                            {state.selectedShipment.file.length === 0 &&

                                <TableRow key={'nofilesother'}>
                                    <TableCell align="left">No Files</TableCell>
                                    <TableCell align="center"></TableCell>
                                 
                                </TableRow>



                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <LabelDisplay name="Lable.pdf" /> */}
            </SuiBox>
          </ShipmentPreviewSectionContainer>}
         

                                    </>
                                }
                                {tabValue === 1 &&
                                    <>
                                      
                                        <CurrentQuote currency={state.selectedShipment.currency} />
                                        <QuoteRequest currency={state.selectedShipment.currency} />
                                    </>
                                }
                                {/* {tabValue === 2 &&
                                    <DocumentsAndLabels state={state.selectedShipment.file} id={state.selectedShipment.id} />
                                } */}
                                {/* {tabValue === 3 &&
                                    <Admin id={state.selectedShipment.id} state={state.selectedShipment} tableType={'carrierSpot'} />
                                } */}

                            </>
                             } 
                        </DialogContent>
                        <Divider />
                        <DialogActions>
                            <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="flex-end">
                                <Grid item >
                                    <SuiButton onClick={handleClose} variant="contained" color="secondary">
                                        Close
                                    </SuiButton>
                                </Grid>

                            </Grid>

                        </DialogActions>

                    </>} 



            </Dialog>
        </React.Fragment>
    );

}





