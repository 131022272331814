import { each } from "jquery";


export function GooglePlaces(value,country, callback){


  const options = {
    componentRestrictions: { country: country },
    fields: ["address_components"],
    strictBounds: false,
  };
  
  console.log(value)
  const service = new window.google.maps.places.AutocompleteService();
  if(value && value !== ''){
     service.getPlacePredictions({ input: value ?? '', componentRestrictions: {country: country}, types:['locality','postal_code','administrative_area_level_1'] }, callback);
  }

  return []
 
}

export function GoogleGetDetails(id,callback){

  var request = {
    placeId: id,
    // fields: ['name', 'rating', 'formatted_phone_number', 'geometry']
  };

  
  const service = new window.google.maps.places.PlacesService(document.createElement('div'));
  service.getDetails(request, callback);
 
  
  
 
}

export function GetStateFromPlace(item){

 
    if(item.types[0] === 'administrative_area_level_1'){
      return item.short_name
    }

  



}

