import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {  useSelector } from 'react-redux';
import { Paper } from "@mui/material";
import { ResultsRow } from './ResultsRow'
import Loader from 'react-dots-loader'
import 'react-dots-loader/index.css'
import { Banner } from '_components/Layout/Banner';
import Alert from '@mui/material/Alert';

function checkGroupage(x) {
  console.log(x)
  return x.groupage === false;
}

export function ResultsContainer() {


  const state = useSelector(state => state.instantPricing);
  const groupage = !state.results.some(checkGroupage)
  console.log('gourp',groupage)

  return (
    <Grid container sx={{ overflow: 'unset', '.MuiPaper-root': { overflow: 'unset' } }}>

      {state.calculating && (



        <Grid container spacing={1} sx={{marginTop:'2rem'}} alignItems="center" alignContent="center" direction="column">
          <Grid item textAlign="center">
            <h1 style={{ 'color': '#bbb',marginBottom:'0rem' }}>Calculating</h1>
            <Loader  size={7} color="#183B59" distance={30}/>

          </Grid>
   
        </Grid>
       

      )}
      {!state.calculating && state.noResults && (
      <Grid container alignItems="center" sx={{pt:'2rem'}} alignContent="center" direction="column">
          <h2 style={{ 'color': '#bbb' }}>Sorry, no prices available!</h2>
        </Grid>
      )}

      {!state.calculating && !state.noResults && (
        <>
        <Grid container alignItems="center" sx={{pt:'2rem'}} alignContent="center" direction="column">
          {!groupage && state.results.length> 0 && 
             <Paper mb={1} sx={{width:'100%'}}>
              <Alert severity="warning" sx={{ width: '100%', '& .MuiAlert-message': { width: '100%' }, padding: '10px' }} >
                <Grid container>
                  <Grid item xs={12}>
                  <Typography sx={{ fontSize: 12, px: 2, py: 0 }} color="text.secondary" gutterBottom>
                  Please note: For part-load and full-load shipments, the displayed delivery time is based on the assumption that pickup will occur tomorrow. The actual delivery time will be calculated after the pickup is scheduled, as it requires additional planning and equipment availability. For more information regarding the schedule, please contact customer service.
           </Typography>
                  
                  </Grid>
                 
                </Grid>
              </Alert>
              </Paper>
          }
         
          {state.results.map((x, index) => <ResultsRow key={index} row={x} />)}
        </Grid>
        </>
      )}


    </Grid>
  )
}